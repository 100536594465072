<script>
import Layout from "../../layouts/main";
import appConfig from "../../../../app.config.json";
import { getAuth, signInAnonymously } from "firebase/auth";


/**
 * Sales-Dashboard component
 */
export default {
  page: {
    title: "Redcabs Admin Portal",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout
  },
  methods: {
    fetchNotifications(){
      this.$store.dispatch('getNotificationMessages', 'support')
      this.$store.dispatch('getNotificationMessages', 'receipt')
    }
  },
  created() {
    this.fetchNotifications();
    const user = this.$store.getters.GET_USER_INFO
    const routePath = this.$route.path;
    let externalUserId = user['id']; // You will supply the external user id to the OneSignal SDK
    OneSignal.push(() => {
      OneSignal.setExternalUserId(externalUserId).then((res) => {
        window.OneSignal.push(() => {

          window.OneSignal.getExternalUserId().then( (externalUserId) => {
            console.log("externalUserId: ", externalUserId);
          });

          // window.OneSignal.on('notificationDisplay', (event) => {
          //   console.warn('OneSignal notification displayed:', event);
          //   console.log('current route: ', routePath)
          //   this.fetchNotifications()
          // });


        });
      });

    });

  //  firebase anonymous sign in ---------

    const auth = getAuth();
    if(!auth.currentUser){
      signInAnonymously(auth)
          .then(() => {
            // Signed in..
            console.log('firebase anonymous login success: ')
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log('firebase login error: ', error)
            // ...
     });
    }

  }
};
</script>

<template>
  <Layout>
    <router-view></router-view>
  </Layout>
</template>
